<template>
  <div class="home">
    <Banner/>  
    <Intro/> 
    <Trabajo/> 
    <Socios/>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/Banner'
import Intro from '@/components/Intro'
import Trabajo from '@/components/Trabajo'
import Socios from '@/components/Socios'

export default {
  name: 'HomeView',
  components: {
    Banner, Intro, Trabajo, Socios
  }
}
</script>
