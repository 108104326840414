<template>
    <section id="section_banner" class="d-flex flex-column ">           
        <video class="video_vertical" src="../assets/videos/hero_dside_vertical_musica.mp4" playsinline autoplay loop muted ></video>
        <video class="video_horizontal" src="../assets/videos/hero_final_dside_musica.mp4" playsinline autoplay  loop muted ></video>              
    </section>
</template>

<style>

#section_banner{
    width: 100vw;
    height: auto;
    position: relative;
    overflow: hidden;
}

#section_banner video{
    -o-object-fit: cover;
       object-fit: cover;
    width: 100vw;
    height: 100%;
 }
 
 #section_banner .video_horizontal{
     display: none;
 }

 

</style>