<template>
    <section id="section_foot" class="d-flex flex-column flex-wrap justify-content-center align-items-center">   
        <div class="section_cont_foot d-flex flex-column align-items-center">
            <div class="cont_foot_principal d-flex flex-column align-items-center justify-content-center flex-sm-row justify-content-lg-around mb-3">
                <div class="logo_foot my-5 mx-auto ">
                    <img class="w-100" src="../assets/images/logos/dside_logo.svg" alt="">
                </div>
                <div class="cont_redes_foot d-flex align-items-center justify-content-center  mb-3 mx-auto">
                    <div class="cont_logo mx-2">
                        <a target="_blank" href="https://www.instagram.com/dark_side_engineering/"><i class="fab fa-instagram"></i></a>
                    </div>
                    <div class="cont_logo mx-2">
                        <a target="_blank" href="https://www.linkedin.com/in/dark-side-engineering-a03095156/"><i class="fab fa-linkedin"></i></a>
                    </div>
                </div>
                <div class="cont_contacto_foot d-flex flex-column align-items-center justify-content-center mb-3 mx-auto mb-sm-0">
                    <div class="cont_textos text-center mb-2">
                        <a href="mailto:creal@dside.tech">creal@dside.tech</a>
                    </div>
                </div>
            </div>
            <div class="cont_foot_secundario d-flex flex-column flex-sm-row align-items-center justify-content-sm-around justify-content-xxl-between">
                <div class="cont_textos mb-3">
                    <p class="textos text-center">Dside © |  2022    
                        
                        <!-- <span><a target="_blank" href="https://b312studio.com">b312studio</a></span> -->
                    
                    </p>
                </div>
                <div class="cont_video_foot">
                    <video src="../assets/videos/tentaculo_anima.mp4" playsinline autoplay muted loop></video>
                </div>
            </div>
        </div>
    </section>
</template>

<style>

#section_foot .cont_foot_principal .logo_foot{
    width: 45%;
}

#section_foot .cont_logo i{
    font-size: 3rem;
}

#section_foot .cont_contacto_foot .cont_textos a,
#section_foot .cont_foot_secundario .cont_textos p{
    font-size: 1.5rem;
    font-family: forma-djr-deck, sans-serif;
    font-style: normal;
}


#section_foot .cont_video_foot{
    width: 40%;
    height: auto;
}

#section_foot .cont_video_foot video{
    width: 100%;
}
</style>