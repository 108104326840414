<template>
    <section id="section_nosotros" class="d-flex w-100">   
        <div class="section_cont_nosotros d-flex flex-column align-items-center justify-content-center align-items-sm-start py-4 my-4 py-xxl-5">
           <div class="subsection_one d-flex flex-column py-3 mb-4">
                <div class="cont_textos px-5 py-3 py-sm-4 py-lg-3">
                    <h1 class="titulo text-end">Somos <span>d</span>side</h1>
                </div>
                
                <div class="video_nosotros align-self-end">
                    <video class="w-100"  playsinline autoplay  loop muted src="../assets/videos/nosotros_01_1.mp4"></video>
                </div>
                <div class="cont_textos intro_video p-3">
                    <h3 class="subtitulo">Un laboratorio de desarrollo de <span>tecnologías.</span></h3>
                </div>
           </div>
           <div class="subsection_two mb-4">
                <div class="cont_textos p-4 py-sm-4 py-lg-3">
                    <h3 class="subtitulo">Enfocamos su aplicación en sectores tan variados como:</h3>
                    <p class="textos">· Ingeniería.</p>
                    <p class="textos">· Gestión del aseguramiento de la calidad.</p>
                    <p class="textos">· Automatización de trading de activos financieros.</p>
                </div>               
           </div>
           <div class="subsection_three d-flex flex-column py-3 mb-4">
                <div class="video_nosotros align-self-end">
                    <video class="w-100" playsinline autoplay  loop muted src="../assets/videos/nosotros_02.mp4"></video>
                </div>

                <div class="cont_textos intro_video p-3">
                    <h3 class="subtitulo">Nuestro equipo está formado por jóvenes ingenieros y desarrolladores talentosos.</h3>
                </div>
                <div class="d-sm-flex flex-wrap  align-self-lg-center">
                    <div class="video_nosotros  mt-sm-0">
                        <video class="w-100" playsinline autoplay  loop muted src="../assets/videos/nube_01.mp4"></video>
                    </div>
    
                    <div class="cont_textos p-4 py-sm-4 py-lg-3 mx-lg-auto align-self-lg-center">
                        <h3 class="subtitulo">Trabajamos permanentemente en la <span>investigación </span> e integración de tecnologías.</h3>
                        <p class="textos">Lo que nos permite estar siempre unos pasos por delante de nuestros competidores y que nuestros clientes puedan aprovecharse de nuestro conocimiento y experiencia avanzados.</p>
                    </div>
                </div>
                 
           </div>
           <div class="subsection_four mb-4">
                <div class="cont_textos px-5 py-3 py-sm-4 py-lg-3">
                    <h1 class="titulo text-end">Conoce al <span>equipo</span></h1>
                </div>
                <div class="cont_miembros_nosotros d-flex flex-wrap flex-column justify-content-center mx-xxl-auto">
                    <div class="cont_miembro p-4">                        
                        <div class="d-flex flex-column flex-sm-row align-items-sm-center flex-wrap">
                            <div class="cont_img_miembro m-3">
                                <img class="w-100" src="../assets/images/pedro_cv.png" alt="">
                            </div>
                            <div class="cont_textos d-flex flex-column ">
                                <h2 class="subtitulo my-3">Pedro Sánchez - Barrio</h2>
                                <h3 class="subtitulo">Director General</h3>
                                <a class="subtitulo correo_miembro mb-3" href="mailto:psbarrio@dside.tech">psbarrio@dside.tech</a>                                
                            </div>
                            <p class="textos p-md-2">Su misión es llevar al equipo al éxito mediante el diseño y ejecución de la estrategia de negocio, así como la coordinación de los esfuerzos de las distintas áreas de negocio de la Compañía. Además es responsable de la Dirección Financiera y de las áreas de soporte al negocio. <span class="mostrar" v-on:click="toggle">Ver más</span></p>
                            <p class="textos p-md-2" v-show="showSection">Pedro tiene más de 30 años de experiencia en varios países de Europa y América en consultoría estratégica internacional para compañías transnacionales e instituciones gubernamentales, en gestión de empresas tecnológicas y en análisis financiero. </p>
                        </div>
                    </div>

                    <div class="cont_miembro p-4">
                        <div class="d-flex flex-column flex-sm-row-reverse align-items-sm-center flex-wrap">
                            <div class="cont_img_miembro m-3">
                                <img class="w-100" src="../assets/images/christ_cv.png" alt="">
                            </div>
                            <div class="cont_textos d-flex flex-column ">
                                <h2 class="subtitulo my-3 text-end">Christian Real Pindter</h2>
                                <h3 class="subtitulo text-end">Director de Operaciones y Proyectos</h3>
                                <a class="subtitulo correo_miembro mb-3 text-end" href="mailto:creal@dside.tech">creal@dside.tech</a>
                            </div>
                            <p class="textos p-md-2">Su misión es ejecutar de manera exitosa la estrategia operativa de la compañía, que incluyen la acción comercial, gestión de proveedores y clientes, calidad de los proyectos y ejecución de instalaciones. <span class="mostrar" v-on:click="toggle2">Ver más</span></p>
                            <p class="textos p-md-2" v-show="showSection2">Christian lleva más de 12 años de experiencia en estimación de costes, especificación de equipos y ejecución de soluciones sectoriales en el ámbito de instalaciones industriales.</p>
                                <p class="textos p-md-2" v-show="showSection2">Es líder del equipo de mejora continua y certificación ISO 9001 de la empresa coordinando las distintas áreas operativas de la compañía.</p>
                                            
                        </div>
                        
                    </div>

                    <div class="cont_miembro p-4">
                        <div class="d-flex flex-column flex-sm-row align-items-sm-center flex-wrap">
                            <div class="cont_img_miembro m-3">
                                <img class="w-100" src="../assets/images/erik_cv.png" alt="">
                            </div>
                            <div class="cont_textos d-flex flex-column ">
                                <h2 class="subtitulo my-3">Erik Real Pindter</h2>
                                <h3 class="subtitulo">Director de Ingeniería e Innovación.</h3>
                                <a class="subtitulo correo_miembro mb-3" href="mailto:ereal@dside.tech">ereal@dside.tech</a>                                
                            </div>
                            <p class="textos p-md-4">Su misión es asegurar la realización de la mejor ingeniería de diseño y cálculo, así como optimizar la aplicación de las nuevas tecnologías en su desarrollo y ejecución. <span class="mostrar" v-on:click="toggle3">Ver más</span></p>
                            <p class="textos p-md-4" v-show="showSection3">Supervisa los proyectos y soluciona las dificultades que se presentan dando soluciones originales a los problemas. Dedica gran parte de su tiempo a  investigar y evaluar el estado del arte en tecnología y su adaptación y aplicación en nuestros proyectos y procesos de trabajo.</p>
                            <p class="textos p-md-4" v-show="showSection3">Erick lleva más de 15 años en el sector de filtración industrial y es uno de los mayores expertos mexicanos en tecnologías de proceso visual y realidad aumentada, así como en ingeniería industrial medioambiental.</p>
                       
                        </div>
                    </div>

                    <div class="cont_miembro p-4">
                        <div class="d-flex flex-column flex-sm-row-reverse align-items-sm-center flex-wrap">
                            <div class="cont_img_miembro m-3">
                                <img class="w-100" src="../assets/images/darthar_cv.png" alt="">
                            </div>
                            <div class="cont_textos d-flex flex-column ">
                                <h2 class="subtitulo my-3 text-end">Armando Aboytes</h2>
                                <h3 class="subtitulo text-end">Jefe de Proyecto Senior</h3>
                                <a class="subtitulo correo_miembro mb-3 text-end" href="mailto:a.aboytes@dside.tech">a.aboytes@dside.tech</a>                                
                            </div>
                            <p class="textos p-md-4">Su misión es el aseguramiento del éxito técnico de los proyectos mediante la dirección y supervisión de los cálculos, análisis, diseño y  entregables que componen el proyecto, así como el desarrollo profesional de los ingenieros a su cargo. <span class="mostrar" v-on:click="toggle4">Ver más</span></p>
                            <p class="textos p-md-4" v-show="showSection4">La experiencia de Armando se centra en el diseño técnico de instalaciones, cálculo de estructuras y mecánica de fluidos, tanto en construcción como industrial, así como en la dirección de equipos técnicos y capacitación de los mismos. Asesora a los clientes dando directrices concretas a la hora de ejecutar la instalación. Su colaboración profesional es siempre muy valorada por los clientes.</p>
                        </div> 
                    </div>
                </div>
           </div>
        </div>             
    </section>
</template>

<script>
export default {
    data() {
        return {
            showSection: false,
            showSection2: false,
            showSection3: false,
            showSection4: false,
        }
    },
    methods: {
        toggle() {
            this.showSection = !this.showSection
           },
           toggle2() {
            this.showSection2 = !this.showSection2
           },
           toggle3() {
            this.showSection3 = !this.showSection3
           },
           toggle4() {
            this.showSection4 = !this.showSection4
           },
    },
}
</script>

<style>
    
#section_nosotros{
}

.mostrar{
    color: var(--rojo);
}

.mostrar:hover{
    cursor: pointer;
}

#section_nosotros .section_cont_nosotros {
  width: 100%;
  background: var(--blanco);
}

#section_nosotros .section_cont_nosotros .subsection_one{
    width: 100%;
}

#section_nosotros .section_cont_nosotros .subsection_one .video_nosotros{
    width: 85%;
    right:0 ;
    margin-top: 5%;

}

#section_nosotros .section_cont_nosotros .subsection_one .intro_video,
#section_nosotros .section_cont_nosotros .subsection_three .intro_video{
    width: 85%;
    position: relative;
    margin-top: -10%;
    background-color: #4e4c4ce3 ;
}


#section_nosotros  .intro_video .subtitulo{
    color: var(--blanco);
    text-align: justify;
    word-break:normal;
}

#section_nosotros .section_cont_nosotros .subsection_two .cont_textos .titulo{
    text-align: justify;
}

#section_nosotros .section_cont_nosotros .subsection_three .d-sm-flex{
    width: 100%;
    margin-top: 15%;
}

#section_nosotros .section_cont_nosotros .subsection_three .video_nosotros{
    width: 80%;
}

#section_nosotros .section_cont_nosotros .subsection_four{
    width: 100%;
}

#section_nosotros .section_cont_nosotros .subsection_four .cont_textos .titulo {
    margin-right: 0;
}

#section_nosotros .section_cont_nosotros .subsection_four .cont_textos .titulo  span{
   
    text-align: right,
}

#section_nosotros .section_cont_nosotros .subsection_four .cont_miembros_nosotros{
    width: 100%;
}

#section_nosotros .section_cont_nosotros .subsection_four .cont_miembros_nosotros .cont_miembro{
    width: 100%;
}


#section_nosotros .section_cont_nosotros .subsection_four .cont_miembros_nosotros .cont_miembro .cont_textos:nth-child(2){
    text-align: justify;
}

#section_nosotros .section_cont_nosotros .subsection_four .cont_miembros_nosotros .cont_miembro .cont_textos:nth-child(2) .correo_miembro{
    font-size: 1.4rem;
    color: var(--rojo);
  width: 100%;
}

#section_nosotros .section_cont_nosotros .subsection_four .cont_miembros_nosotros .cont_miembro .cont_textos:nth-child(2) .correo_miembro:hover{
  text-decoration: underline;
}

#section_nosotros .section_cont_nosotros .mostrar:hover{
    font-weight: bold;
}
</style>