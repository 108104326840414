import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView'
import Proyectos from '../views/Proyectos'
import Nosotros from '../views/Nosotros'
import Trabajo from '../components/Trabajo'
import Historia from '../views/Historia'

const routes = [
  {
    path: '/',name: 'home', component: HomeView
  },
  {path:'/equipo', name:'nosotros', component:Nosotros},
  {path:'/proyectos', name:'proyectos', component:Proyectos},
  {path:'/trabajo', name:'Trabajo', component:Trabajo},
  {path:'/historia', name:'historia', component:Historia},
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
