<template>
  <Menu/>
  <router-view/>
  <Footer/>
</template>

<script>
 import Menu from '@/components/Menu'
 import Footer from '@/components/Footer'

export default {
  name: 'Home',
  
    components: {
      Menu, Footer
    }, 
}
</script>

<style>
  /* Root Styles */

:root{
    --negro: #1a181b;
    --blanco: #FCFFFC;
    --rojo: #dc493a;
    --verde: #5fad41;
}

.titulo{
    font-family: forma-djr-deck, sans-serif;
    font-weight: 600;
    font-style: normal;
}

.subtitulo{
    font-family: forma-djr-deck, sans-serif;
    font-weight: 600;
    font-style: normal;
}

.textos{
    font-family: revolution-gothic, sans-serif;
    font-weight: 300;
    font-style: italic;
    font-size: 1.5rem;
    text-align: justify;
}

.cont_textos span{
    color: var(--rojo);
}

.enlaces_menu{
    font-family: guanabara-sans, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.5rem;
}

.enlaces_menu a span:hover{
    color: var(--rojo);
}

.boton{
    font-family: revolution-gothic, sans-serif;
    font-weight: 300;
    font-style: italic;
    font-size: 1.5rem;
    border: 2px solid var(--rojo);
}

.boton:hover{
    background: var(--rojo);
    transition: 0.5s;
    color: var(--blanco)
}

.boton:focus{
    background: var(--rojo);
    transition: 0.5s;
    color: var(--blanco)
}

html,
body{
    overflow-x: hidden;
    width: 100vw;
    background: var(--blanco);
}

*{
    margin:0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    
}

a:hover{
    color: var(--rojo);
}

ul, li {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

section{
    width: 100vw;
    /* overflow-x: hidden; */
    height: auto;
    position: relative;
}

#preloader{
    background: var(--blanco)  url(./assets/images/loaader_dside.gif) no-repeat center center;
    background-size: 50%;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 100;
}
</style>
