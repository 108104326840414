<template>
    <section id="section_historia" class="d-flex w-100">   

        <div class="section_cont_historia d-flex flex-column py-4 my-4 py-xxl-5">
            <div class="subsection_one d-flex flex-column">
                <div class="cont_textos align-self-end px-5 mt-4 py-sm-4 py-lg-3">
                    <h1 class="titulo  text-end">Historia de una profunda <span>transformación digital</span></h1>
                </div>
            </div>    

            <div class="subsection_two d-flex flex-column">
                <div class="cont_textos mb-5 mx-auto">                             
                    <p class="textos">Selecciona tu idioma:</p>            
                </div>    
                <div class="cont_botones_historia d-flex mx-auto">
                    <div class="cont_boton">
                        <button class="btn mx-4 boton"  v-on:click="toggle">Español</button>
                    </div>
                    <div class="cont_boton">
                        <button class="btn mx-4 boton" v-on:click="toggle2">English</button>
                    </div>
                </div>
            </div>

            <div class="subsection_three" v-show="showSpanish">
                <div class="cont_historias w-100">

                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos mb-5  align-self-center">                             
                                <p class="textos">Por:<span> Pedro Sánchez - Barrio</span></p>
                                <p class="textos"> Director General</p>   
                                <p class="textos">Dside Engineering</p>                    
                            </div>    

                            <div class="cont_textos">
                                <p class="textos">En estos momentos en que se habla tanto de “transformación digital” en distintos sentidos, me gustaría compartir con ustedes nuestra experiencia en ese aspecto: de cómo en DARK SIDE ENGINEERING (www.dside.tech) hicimos nuestra “transformación digital” y también ofrecerles ciertos “tips” que fueron muy valiosos para nosotros y que en cierto modo determinaron las claves para el éxito. No deja de ser nuestro punto de vista basado en nuestra experiencia.</p>
                            </div>
                        </div>        
                    </div>    

                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo my-2">1.- <span>Entendiendo</span> todos lo mismo. Definir en qué consiste la “transformación digital” y por qué la realizamos</h2>                          
                            </div>    

                            <div class="cont_textos">
                                <p class="textos">En DSIDE conceptualizamos la “transformación digital” como el proceso de cambio y adaptación de toda la empresa, incluyendo personas, productos y servicios, para aprovechar al máximo las nuevas tecnologías y generar ventajas competitivas.</p>
                                <p class="textos">Para nosotros fue un proceso de redefinición casi total, donde la tecnología actuó como vector de cambio pero no fue el cambio en sí mismo. En otras palabras, identificamos nuevas oportunidades para aprovecharlas con nuevas herramientas tecnológicas que en algunos casos ni siquiera existían, e imaginamos cómo queríamos abordar el futuro, lo que nos llevó a tener que revisar nuestro negocio y nuestra empresa para adaptarnos a la situación.</p>
                                <p class="textos">La decisión de realizar una “transformación digital” no fue ni un capricho ni una moda, sino que surgió de la necesidad imperiosa de sobrevivir en un sector supercompetitivo: la ingeniería de instalaciones industriales. En 2018, la situación en México y en Latinoamérica en general, se había puesto verdaderamente complicada. Por un lado, unos precios cada vez más bajos, donde la venta de las máquinas supone la mayor parte del precio final. Por otro, la factura que representan las instalaciones en comparación con el precio del estricto proyecto de ingeniería es varias veces superior y además, en muchos países ni siquiera es preceptivo, pasando a ser una comodity que en ocasiones hasta se regala con tal de ganar el conjunto del proyecto. Y finalmente, para nosotros que sólo hacíamos ingeniería, desaparecer del mercado en este escenario, era una simple cuestión de tiempo. Por eso, a principios de 2019 cuando empezamos con la transformación, ya era una necesitad urgente de “reinvención para la supervivencia”.</p>
                            </div>
                        </div>        
                    </div>    
                    
                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo my-2">2.- <span>Definiendo bien los objetivos</span>: Qué es lo que queremos y qué es lo que NO queremos</h2>                          
                            </div>    

                            <div class="cont_textos">
                                <p class="textos">En nuestro proyecto queríamos:</p>
                                <p class="textos">Cambiar el concepto de nuestra oferta (la ingeniería) y como consecuencia, también de nuestro trabajo. Distinguirnos absolutamente de la competencia haciendo cosas que impresionen pero que sean abordables. Que nuestro trabajo aporte un valor real, tangible y demostrable. En resumen, que los clientes nos perciban, nos aprecien y nos valoren por nuestra diferencia en el trabajo. No queríamos simplemente seguir haciendo lo mismo pero más barato y con otras herramientas.</p>
                                <p class="textos">Tener al menos un par de ventajas competitivas claras, valorables y difícilmente imitables a corto plazo. No queríamos entrar en la clásica guerra comercial por precio, sin ningún tipo de diferenciación que el cliente pueda percibir y sobre todo apreciar.</p>
                                <p class="textos">Replantearnos nuestro mercado objetivo. Segmentar correctamente nuestros clientes. En otras palabras, no disparar a todo lo que se mueve y concentrar los esfuerzos en un mercado bien estudiado.</p>
                                <p class="textos">Crear una nueva cultura de empresa. Definitivamente, no queríamos ni podíamos quedarnos parados con la misma cultura.</p>
                                
                            </div>
                        </div>        
                    </div>  
                    
                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo my-2">3.- <span>Planteando el sentido</span> de la transformación y las líneas de acción</h2>                          
                            </div>    

                            <div class="cont_textos">
                                <p class="textos">Por un lado, redefinimos nuestra propuesta de valor.</p>
                                <p class="textos">Para ello tuvimos que hablar mucho con potenciales clientes. Organizamos grupos de enfoque y concepto (focus group) y les invitamos a echar la imaginación a volar, a querer algo que en este momento nadie les da y que probablemente ni exista, pero que les podría ser tremendamente útil.</p>
                                <p class="textos">•	¿Qué te parecería visualizar previamente la instalación?. Ver como va a quedar, meterte en ella, caminar por su alrededor, incluso “tocarla”…  Comprobar si hay intersecciones, cruces, simular varios escenarios con sus respectivos cálculos, tomar medidas reales, tener los planos de fabricación y montaje en 3D, enseñarle a tu jefe “tu proyecto” antes de que exista…</p>
                                <p class="textos">También les ayudamos a reflexionar acerca de lo que realmente necesitan y los riesgos asociados…</p>
                                <p class="textos">•	¿Qué estas comprando?, ¿Unas máquinas?, ¿Unos tubos de acero?... ¿O la seguridad de que “tu proyecto” es una solución segura, fiable, abordable y sobre todo una realidad?</p>
                                <p class="textos">•	¿Vas a admitir la mínima posibilidad de poner en riesgo la salud y seguridad de tus empleados o de tu producción?</p>
                                <p class="textos">Con la información obtenida diseñamos un nuevo delivery, prestando atención a aquellos aspectos que al cliente le gustaría tener y no tiene, le gustaría ver y no ve, le gustaría imaginar y no es capaz de imaginar.</p>
                                <p class="textos">A la vez estudiamos qué herramientas hay en el mercado para realizarlo, con el objetivo de adoptarlas y si no existen, crearlas.</p>
                                <p class="textos">Por otro lado, también redefinimos qué tipo de equipo queríamos ser y esbozamos las líneas de la cultura de empresa.</p>
                                <p class="textos">•	Nos respondimos a varias preguntas, tales como:  ¿Qué sentido tiene para tí el trabajo?,  ¿Cómo quieres que sea tu equipo?,  ¿Cómo te gustaría que fuera tu relación con tu entorno?,  ¿Qué podrías aportar para distinguirte, más allá de un mero cumplimiento?,  ¿Qué esperas de tus compañeros?,  ¿Cómo te visualizas trabajando dentro de 10 años?, ¿Cuáles son tus principales valores sociales y morales?...></p>
                            </div>
                        </div>        
                    </div>    

                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo my-2">4.- <span>El equipo</span> de proyecto: nuestro “dream team”</h2>                          
                            </div>    

                            <div class="cont_textos">
                                <p class="textos">Para realizar este proyecto no acudimos a ninguna consultoría especializada, lo hicimos todo desde el equipo de DSIDE. La razón principal fue sencillamente porque no teníamos presupuesto suficiente, así que decidimos emplear nuestros recursos en investigación y medios, como veremos más adelante, y abordar la aventura con la confianza en nuestra experiencia profesional anterior. Y además teníammos la ilusión por adoptar lo nuevo desde un punto de vista pragmático, un “mindset” colectivo abierto y disruptivo, sin ideas preconcebidas y sobre todo muchas ganas de trabajar y grandes dosis de sentido común.</p>
                                <p class="textos">Con los que ya estábamos y algunas incorporaciones, enseguida estructuramos un magnífico equipo multidisciplinar, realmente heterogéneo, disruptivo e innovador. Compuesto por desarrolladores de videojuegos, expertos en sistemas de computación, ingenieros de instalaciones, ingenieros de proyecto, expertos en CAD… en definitiva, mucho talento, mucha ilusión, mucha imaginación…y lo más importante: mucho compromiso</p>
                            </div>
                        </div>        
                    </div>   

                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo my-2">5.- <span>El roadmap</span>: diseñando un sueño, creando las herramientas</h2>                          
                            </div>    

                            <div class="cont_textos">
                                <p class="textos">Nuestros desarrolladores se pusieron el reto de integrar una instalación diseñada en CAD, con la nube de puntos generada por el escaneo tridimensional de la futura ubicación. El dominio de la tecnología de realidad virtual adquirida en el desarrollo de videojuegos fue sin duda alguna, decisiva. Las gafas de realidad virtual (Oculus) que los desarrolladores usaban para pelear en el videojuego de Star Wars ahora las usan sus compañeros ingenieros para pasear por una instalación industrial, los joystcks con los que empuñaban los sables láser, Darth Vader, el Jedi y Mace Windu son ahora instrumentos de medida de precisión y control…</p>
                                <p class="textos">La amplia experiencia en la ingeniería de instalaciones industriales, montaje y taller fue clave para crear el algoritmo y obtener automáticamente los planos de montaje en 3D, generar los planos de fabricación de la tubería para que lo pudiera fabricar cualquier taller de calderería o pailería.</p>
                                <p class="textos">Casi lo teníamos conseguido. Faltaba automatizar y actualizar en tiempo real los cálculos de pérdida de carga y balanceo, que hasta ahora se hacían con tablas. Desempolvando los viejos apuntes de Mecánica de Fluidos de la universidad, se realizó el diseño funcional y nuestros brillantes desarrolladores hicieron el resto para crear nuestro propio plug-in de balanceo de cargas.</p>
                                <p class="textos">Pero queríamos más. Queríamos que nuestro esfuerzo no fuera sólo flor de un día y llegar a sistematizar nuestros singulares procesos de trabajo, para poder garantizar siempre los mejores resultados… y obtuvimos la certificación de aseguramiento de la calidad ISO 9001.</p>
                                <p class="textos">El resultado fue que ya éramos capaces de realizar un proyecto en la mitad de tiempo con un margen de error mínimo (+- 2mm), con una precisión de cálculo extraordinaria, en sistema decimal e imperial y con una capacidad de corrección inmediata. Un proyecto que puede ser visualizado a cualquier escala, desde cualquier perspectiva, incluso 1:1, y lo mejor del caso: lo podemos hacer en cualquier parte del mundo y sin necesidad de salir de nuestro estudio.</p>
                                <p class="textos">Dejamos de “hacer proyectos” para convertirnos en “materializadores de sueños”. Pasamos de ser completamente prescindibles para el cliente en el conjunto de una instalación, para ser ahora la pieza clave de la misma y visualizar y transmitir el sentido último del proyecto.</p>
                                <p class="textos">Conceptualizamos la visualización previa del conjunto como un valor añadido clave para configurar una solución real y posible, lo que es una ventaja competitiva definitiva. Mucho más allá que la simple “venta de máquinas” que proponen los fabricantes o la instalación de las tuberías fabricadas en taller que venden los paileros. Superamos el concepto de solución haciendo realidad lo virtual.</p>
                                <p class="textos">Finalmente, los clientes se fueron segmentando ellos solos: salieron de nuestro espectro aquellos que todavía no llegan a ver la utilidad de un entregable diferente o simplemente no quieren pagarlo. Y por otra parte se interesaron aquellos que buscan permanentemente la excelencia en su trabajo, que se comprometen con los temas de salud y seguridad de su plantilla y que en definitiva, buscan asegurarse el éxito de su proyecto sin riesgos. Logramos crear en el cliente la necesidad de ver su proyecto antes de empezar, incluso antes de comprar…</p>
                            </div>
                        </div>        
                    </div>  

                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo my-2">6.- <span>Ser diferentes</span> de verdad: Crear una nueva cultura de empresa</h2>                          
                            </div>    

                            <div class="cont_textos">
                                <p class="textos">Si tuviera que decir cual ha sido el factor más importante de nuestro éxito, sin duda alguna este ha sido nuestro equipo. Tenemos rotación cero y lista de espera para trabajar con nosotros, gracias a una peculiar cultura de empresa que nos hace un poco especiales: atraemos el talento y lo retenemos. No tenemos departamento de Recursos Humanos. Y que me perdonen los profesionales de este área pero la mitad del equipo posiblemente no pasaría un test psicotécnico convencional, sin embargo junto a la otra mitad, han demostrado ser capaces de hacer verdaderas genialidades. Los equipos se autogestionan sin mucha complicación. El trabajo se organiza por retos, no por proyectos. La selección del equipo es espontánea, la carrera profesional, clara y con los pasos bien definidos para llegar a ser socios si lo desean. Trabajan con la última tecnología, con los mejores recursos necesarios, crean, innovan, su trabajo es un reto constante, las barreras no existen, lo importante es el compromiso, las ideas, el esfuerzo, los clientes, el resultado… la cultura la hicieron ellos y la fundamentaron en valores compartidos y vividos por todos.</p>
                            </div>
                        </div>        
                    </div>  

                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo my-2">7.- <span>Cosechando resultados</span>. La transformación no acaba y pasa a ser permanente</h2>                          
                            </div>    

                            <div class="cont_textos">
                                <p class="textos">Cambió radicalmente nuestra situación en el mercado: de estar prácticamente fuera de juego en 2019, pasamos a ser líderes, los mejores en nuestra especialidad en 2022. Conseguimos nuestro objetivo inicial y ahora trabajamos para estar siempre un paso por delante de nuestros competidores, repitiendo este proceso permanentemente.</p>
                                <p class="textos">Enseguida DONALDSON, compañía norteamericana, Nº 1 mundial en sistemas de filtración industrial, se interesó por nuestra manera de entender la ingeniería y de trabajar. Confió en DSIDE y nos ofreció la oportunidad de hacer un proyecto, el cual se realizó con éxito y sobrepasó las expectativas. Después nos volvió a encargar otro, esta vez más complejo, para CORTEVA en Paine, Chile, que se realizó sin necesidad de ir allí, desde nuestro estudio de Querétaro, México. El resultado fue igualmente muy satisfactorio y permitió crear una relación de trabajo muy estrecha con DONALDSON, relación que va desde el apoyo en la preventa hasta la entrega “llave en mano”. Ello se tradujo en más proyectos: Duraplay, BPI, BMW, Michelín, Hersey´s.... proyectos complicados, por las restricciones y morfología pero sobre todo por las exigencias de calidad de los clientes.</p>
                                <p class="textos"></p>
                                <p class="textos"></p>
                            </div>
                        </div>        
                    </div>  

                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo my-2"><span>Conclusión</span></h2>                          
                            </div>    

                            <div class="cont_textos">
                                <p class="textos">La llamada “transformación digital” es una buena ocasión para preguntarse si estamos aprovechando las nuevas tecnologías para mejorar nuestra posición competitiva. La tecnología no es un fin en sí misma, sino que es el vector que propicia la transformación.</p>
                                <p class="textos">Hay que imaginar más allá de lo conocido o lo real para cambiar, si fuera necesario, incluso los conceptos de nuestros productos o servicios o la manera de producirlos y ponerlos en el mercado. La tecnología hoy en día, es capaz de facilitar esa nueva visión y hacerla realidad. Pero no nos olvidemos primero y ante todo, hay que tener claro qué es lo que queremos desde el punto de vista funcional, la tecnología viene después.</p>
                                <p class="textos">Las personas deben asumir que no se trata de hacer lo mismo con otras herramientas, sino una nueva conceptualización de la oferta y de la forma de trabajar y de satisfacer la demanda de los clientes, incluso de la manera de hacerlo, y eso requiere una mente abierta y gran capacidad de permanente adaptación. Y por qué no, compromiso con lo que parece imposible.</p>
                                <p class="textos">Y finalmente, organizar un proyecto con objetivos y contenidos claros, gestionarlo con compromiso e ir dando “quick wins”, es decir pequeños éxitos y logros. Estos buenos resultados van trazando el camino satistactorio y a la vez anima al equipo a seguir realizando una transformación de modo permanente. Hay que tener grandes cantidades de sentido común y a la vez de valentía, para romper paradigmas e ideas preconcebidas y ser capaz de diseñar el futuro.</p>
                                <p class="textos">Transformamos radicalmente nuestro negocio: éramos una ingeniería anticuada sin una propuesta de valor concreta y con una visión de mercado completamente local. Hoy miramos al mundo. Somos un laboratorio de desarrollo de tecnologías innovadoras, y líderes en la aplicación a distintos sectores, haciendo posible, tangible y rentable la imaginación: realidad virtual, integración de CAD con realidad aumentada, algoritmos de cálculo, desarrollos en blockchain… todo ello aplicándolo actualmente en Ingeniería de instalaciones, plataformas de e-commerce, videojuegos, robots de inversión financiera, tokens criptográficos… incluso con varias patentes industriales.</p>
                                <p class="textos">En DSIDE nos reinventamos cada día de nuevo. El proceso de transformación se hizo permanente, se convirtió en nuestra manera de trabajar. ¿La receta? Pues aprovechar la imaginación y la tecnología para ello y contar con un equipo comprometido. Seguimos transformándonos permanentemente para estar siempre un paso por delante de nuestra competencia y ayudar a nuestros clientes a visualizar sus sueños, hacerlos realidad y que ellos puedan hacer lo mismo con sus clientes.</p>
                                <p class="textos">Tampoco hubiera sido posible sin la confianza de quieres creyeron en nosotros, en nuestra visión y en nuestro equipo. Se lo agradecemos siempre superando las expectativas. Las compañías estan formadas por personas y siempre son reflejo de la calidad de éstas. Igual que la mafia la componen gangsters sin escrúpulos, las compañías comprometidas con sus valores y sus clientes son el resultado de personas comprometidas con sus valores y sus clientes.</p>
                            </div>
                        </div>        
                    </div>  
                </div>
            </div>
               
            <div class="subsection_four" v-show="showEnglish">
                <div class="cont_historias w-100">
                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos mb-5  align-self-center">                             
                                <p class="textos">By:<span> Pedro Sánchez - Barrio</span></p>
                                <p class="textos">Managing Director</p>  
                                <p class="textos">Dside Engineering</p>                     
                            </div>    

                            <div class="cont_textos">
                                <p class="textos">In these times when there is so much to talk about "digital transformation" in different senses, I would like to share with you our experience in this regard: how at DARK SIDE ENGINEERING (www.dside.tech) we did our "digital transformation", and also offer you some tips that have been very valuable for us and in a way determined the keys to success. It is just our point of view based on our experience.</p>
                            </div>
                        </div>        
                    </div>  

                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo my-2">1.- Everyone must <span>understand</span> the same thing about it. To define what “digital transformation” consists in and why we are carrying it out</h2>                          
                            </div>    

                            <div class="cont_textos">
                                <p class="textos">At DSIDE we conceptualise "digital transformation" as the process of change and adaptation of the entire company, including people, products and services, in order to take advantages of new technologies and to generate real competitive advantages.</p>
                                <p class="textos">For our company it was a process of almost a total redefinition, where technology acted as a vector of change but it was not the change itself. In other words, we identified new opportunities to take advantage of new technological tools that in some cases did not even exist, and we tried to imagine how we would like to approach the future, which led us to review our business and our company to face the situation.</p>
                                <p class="textos">The decision to carry out a “digital transformation” was neither a whim nor a fad, but It rather arose from the imperative need to survive in a super-competitive sector: industrial facilities engineering. In 2018, the situation in Mexico and even in Latin America, had become truly complicated. On one hand, increasingly lower prices, where the sale of the machines accounts for most of the final price. On the other hand, the bill that the facilities means compared to the price of just the engineering project was several times higher and furthermore, in many countries it was not even mandatory and it became just a commodity that was sometimes given away as a gift in order to win the whole project. And finally, for us who only was doing just engineering projects, disappearing from the market was a simple matter of time in that scenario. That is why, at the beginning of 2019 when we started with the transformation, It was already an urgent need for “reinvention for survival”.</p>
                            </div>
                        </div>        
                    </div>  

                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo my-2">2.- <span>Defining the objectives</span> correctly: what do we want and what do we NOT want</h2>                          
                            </div>    

                            <div class="cont_textos">
                                <p class="textos">We wanted in our project:</p>
                                <p class="textos">•	To change the concept of our offering (engineering) and also of our work as a consequence. To distinguish ourselves absolutely from the competitors by doing things that are impressive but affordable. We wanted that our work provided a real, tangible and demonstrable value. In short, to be  perceived, appreciated and valued by the clients for our difference at work. We did not want just going ahead by keep doing the same things but cheaper with some other new tools.</p>
                                <p class="textos">•	To have at least a couple of clear competitive advantages, that are valuable and difficult to imitate in the short term. We did not want to be involved into the classic commercial price war, without any sort of differentiation to be appreciated by the client.</p>
                                <p class="textos">•	To rethink our target market. To segment our customers correctly. In other words, not to shoot everywhere and everything that moves but to concentrate our efforts on a well-studied market.</p>
                                <p class="textos">•	To create a new company culture. We definitely did not want to and could not to remain with the same culture. It should be like buying a new car with a powerful engine with a set of cheap tires.</p>
                            </div>
                        </div>        
                    </div>  

                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo my-2">3.- <span>Setting out the direction</span> of the transformation and lines of action</h2>                          
                            </div>    

                            <div class="cont_textos">
                                <p class="textos">•	On the one hand, we redefined our value proposition.</p>
                                <p class="textos">In order to do this, We had to talk a lot with potential customers. We organised focus and concept groups and We invited them to let their imaginations run wild: to want something that at the moment nobody gives them and that probably doesn't even exist in the market, but that could be tremendously useful to them.</p>
                                <p class="textos">o	How would you like to visualise the installation beforehand? Let see how it is going to look, get inside it, walk around it, even "touch it"... Check if there are some intersections, crossings, simulate several scenarios with their respective calculations, take real measurements, have manufacturing and assembly plans in 3D, show your boss "your project" before it exists...</p>
                                <p class="textos">We also helped them to think about what they really need and the risks involved...</p>
                                <p class="textos">o	What are you buying? Just some machines and steel tubes...? or the certainty that "your project" is a safe, reliable, affordable and above all, a true reality?</p>
                                <p class="textos">o	Are you going to admit even the slightest possibility of jeopardising the health and safety of your employees or your production?</p>
                                <p class="textos">With the information obtained, we designed a new delivery, paying attention to those aspects that the client would like to have and did not have, would like to see and did not see, would like to imagine and was not even able to imagine.</p>
                                <p class="textos">At the same time, we researched what tools were available on the market to make it happen, with the aim of adopting them and, if they did not exist, creating them.</p>
                                <p class="textos">•	On the other hand, we also redefined what kind of team we wanted to be and we sketched the lines of the company culture and values.</p>
                                <p class="textos">We answered several questions, such as: What is the meaning of work for you?, How do you want your team to be like?, How would you like your relationship with your environment to be like?, How could you contribute to distinguish yourself beyond mere compliance?, What do you expect from your colleagues?, How do you see yourself working in 10 years' time?, What are your main social and moral values?... </p>
                            </div>
                        </div>        
                    </div>  

                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo my-2">4.- <span>The project team</span>: our "dream team".</h2>                          
                            </div>    

                            <div class="cont_textos">
                            <p class="textos">To carry out this project we didn't ask for assistance to any specialised consultancy, we did it all with the DSIDE team. The main reason was simply because we didn't have enough budget, so we decided to use our resources in researching and media, as we will see later on, and to approach the adventure with confidence in our previous professional experience, with the enthusiasm of adopting the new under a pragmatic point of view, with an open and disruptive collective mindset, without any preconceived idea and above all a great desire of working and a large amount of common sense.</p>
                                <p class="textos">It was made up of videogame developers, computer system experts, industrial facilities engineers, project engineers, CAD experts... in short, a lot of talent, a lot of enthusiasm, a lot of imagination... and most importantly: lots of commitment.</p>
                            </div>
                        </div>        
                    </div>  

                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo my-2">5.- Designing a dream, creating the tools: <span>The roadmap</span></h2>                          
                            </div>    

                            <div class="cont_textos">
                            <p class="textos">Our outstanding developers took the challenge of integrating a CAD-designed installation with the points cloud file generated by the three-dimensional scan of the future location. The mastery of virtual reality technology acquired in video game development was definitively a key point of success. The virtual reality goggles (Oculus) that the developers was using to fight in the Star Wars videogame are used now by their fellow engineers to walk around an industrial facility, the joysticks with which they wielded the lightsabers as Darth Vader, Jedi or Mace Windu are now precision measuring and control instruments...</p>
                                <p class="textos">The extensive experience in industrial facilities engineering, assembly and workshop was a key point to create the algorithm and automatically obtain the 3D assembly drawings, generating the manufacturing drawings of the pipe so that it could be manufactured by any steel workshop.</p>
                                <p class="textos">We were almost there. What was missing was to automate and update in real time the load loss and balancing calculations, which until now had been done with tables. Dusting off the old Fluid Mechanics university notes, the functional design was done and our brilliant developers did the rest to create our own load balancing plug-in.</p>
                                <p class="textos">However we wanted more. We wanted our effort not to be just a flash in the pan and to systematise our unique work processes, so in this way we could always guarantee the best results... and we obtained the ISO 9001 quality assurance certification.</p>
                                <p class="textos">The result was that we were already able to carry out a project for customers in half the time with a minimum margin of error (+- 2mm), with an extraordinary calculation precision, in decimal or imperial units, and with an immediate correction capacity. A project that can be visualised at any scale, from any perspective, even 1:1. And the best thing about it: we can do it anywhere in the world and without leaving our studio.</p>
                                <p class="textos">We stop "doing projects" and become "materialisers of dreams". We went from being completely dispensable for the client in the whole of an installation, to now being the key piece of the installation and visualising and transmitting the ultimate meaning of the project.</p>
                                <p class="textos">We conceptualise the preview of the whole as a key added value to configure a real and possible solution, which is a definitive competitive advantage. Beyond the simple "sale of machines" proposed by manufacturers or just the installation of pipes manufactured in the workshop by steel workshops. We overcome the concept of a solution by making the virtuality a tangible reality.</p>
                                <p class="textos">Finally, the customers began to segment themselves: those who did not yet see the usefulness of a different deliverable or simply did not want to pay for it, left our spectrum. And on the other hand, those who are permanently looking for excellence in their work, who are committed to the health and safety issues of their staff and who, in short, are looking to ensure the success of their project without any risk, became interested. We managed to create in the client, the need to see their project before starting, even before buying...</p>
                            </div>
                        </div>        
                    </div>  

                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo my-2">6.- <span>Being truly different</span>: Creating a new company culture</h2>                          
                            </div>    

                            <div class="cont_textos">
                            <p class="textos">If I had to say what has been the most important factor in our success, without a doubt it has been our team. We have zero turnover rate and a waiting list to work with us, thanks to a peculiar company culture that makes us a bit special: we attract talent and we retain it dynamically. We don't have a Human Resources department. And may the professionals in this area forgive me, but half of our team would probably not pass a conventional psycho-technical test, however together with the other half, they have proven capabilities of true geniuses. The teams are self-managed without so much complication. The work is organised by challenges, not by projects. The selection of the team is spontaneous, the career path is clear and with well-defined steps to become partners if they wish it. They work with the latest technology, with the best necessary resources, they create, they innovate, their work is a constant challenge, barriers do not exist, what is important is the commitment, the ideas, the effort, the clients, and the results... Our culture was made by them and it is shared and lived by all of us.</p>
                            </div>
                        </div>        
                    </div>  

                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo my-2">7.- <span>Harvesting results</span>. The transformation does not end and becomes permanent</h2>                          
                            </div>    

                            <div class="cont_textos">
                            <p class="textos">It radically changed our position in the market: from being practically out of the game in 2019, we became leaders, the best in our specialty in 2022. We achieved our initial objective and We are now working to stay one step ahead, repeating this process permanently.</p>
                                <p class="textos">DONALDSON, a US company, world leader in industrial filtration systems, immediately became interested in our way of understanding engineering and working. They trusted in DSIDE and offered us the opportunity to carry out a project, which was successfully completed and exceeded expectations. They then commissioned another one, this time more complex, for CORTEVA in Paine, Chile, which was carried out without the need to go there, in our studio in Querétaro, Mexico. The result was also very satisfactory and allowed us to create a very close working relationship with DONALDSON, a relationship that ranges from pre-sales support to turnkey delivery. This resulted in further projects: Duraplay, BPI, BMW, Michelin, Hersey's.... complex projects due to the constraints and design but above all because of the quality requirements of the customers.</p>
                            </div>
                        </div>        
                    </div>  

                    <div class="cont_historia my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_historia d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo my-2"><span>Conclusion:</span></h2>                          
                            </div>    

                            <div class="cont_textos">
                            <p class="textos">The so-called "digital transformation" is a good opportunity to ask ourselves whether we are taking advantage of new technologies to improve our competitive position. Technology is not an end in itself, but rather the vector that drives transformation.</p>
                                <p class="textos">We must imagine beyond what is known or what is real in order to change, and if necessary, even the concepts of our products or services or the way of producing and marketing them. Technology today, is capable of facilitating this new vision and making it a reality. But let's not forget, first and foremost, we must be clear about what we want from a functional point of view, technology comes later.</p>
                                <p class="textos">People must assume that it is not about doing the same things with other tools, but a new conceptualisation of the offering and the way of working and satisfying customer demand, even the way of doing it, and this requires an open mind and a great capacity for permanent adaptation. And why not, commitment to seems like impossible.</p>
                                <p class="textos">And finally, to set up a project with clear objectives and content, managing it with commitment and giving "quick wins", that is small successes and achievements. These good results will trace the path to success and at the same time encourages the team to continue making a permanent transformation. A great amount of common sense and courage is required to break paradigms and preconceived ideas and to be able to design the future.</p>
                                <p class="textos">We radically transformed our business: we were an old-fashioned engineering company without an specific value proposition and with a local market vision. Today we look at the world. We are a laboratory for the development of innovative technologies, and leaders in the application to different sectors, making imagination possible, tangible and profitable: virtual reality, CAD integration with augmented reality, calculation algorithms, blockchain developments... all of this currently being applied in facilities engineering, e-commerce platforms, videogames, financial investment robots, cryptographic tokens... even with several industrial patents.</p>
                                <p class="textos">At DSIDE we are reinventing ourselves every day. The transformation process became permanent, it became our way of working. What is the recipe? Well, to harness imagination and technology and to have a committed team. We continue to transform ourselves permanently to always be one step ahead of our competitors and to help our clients to visualise their dreams, to make them come true and for them to be able to do the same for their clients.</p>
                                <p class="textos">Nor would it have been possible without the trust of those who believed in us, in our vision and in our team. We really appreciate it by always exceeding their expectations. Companies are made up of people and they are always a reflection of their quality. Just as the mafia is the result of the union unscrupulous gangsters, companies committed to their values and their customers are the result of people committed to their values and their customers.</p>
                            </div>
                        </div>        
                    </div>  
                </div>  
            </div>   
        </div> 
    </section>
</template>

<script>
export default {
    data() {
        return {
            showEnglish: false,
            showSpanish: false,
        }
    },
    methods: {
        toggle() {
            this.showSpanish = !this.showSpanish
            
            this.showEnglish = false
           },
           toggle2() {
            this.showEnglish = !this.showEnglish
            this.showSpanish = false
           },
    },
}
</script>