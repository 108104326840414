<template>
    <section id="section_trabajo" class="d-flex flex-column flex-wrap justify-content-center align-items-center my-md-5 flex-lg-row">   
        <div class="section_cont_trabajo d-flex align-items-center justify-content-center px-5 my-5">
            <div class="cont_textos text-lg-center">
                <h1 class="titulo">¿Cómo lo hacemos?</h1>
            </div>
        </div> 
        
        <div class="section_cont_trabajo_lotties">
           <div class="cont_trabajo my-5 p-3 d-sm-flex flex-lg-column">
                <div class="cont_trabajo_lottie px-sm-2 mx-auto d-flex align-items-center justify-content-center justify-content-end mb-4 mb-sm-2">
                    <lottie-player class="lottie w-100 h-auto" src="https://assets8.lottiefiles.com/packages/lf20_uomppsai.json" background="none"  speed="1"  loop autoplay></lottie-player> 
                </div>
               <div class="cont_trabajo_descripcion">
                   <div class="cont_textos">
                       <h2 class="subtitulo mx-sm-3">Análisis</h2>
                        <p class="textos text-justify mx-sm-3">Cada proyecto es <span>único</span> por lo cual realizamos un estudio y desarrollamos soluciones originales.</p>
                   </div>
               </div>
           </div>
           <div class="cont_trabajo my-5 p-3 d-sm-flex flex-lg-column">
                <div class="cont_trabajo_lottie px-sm-2 mx-auto d-flex align-items-center justify-content-center justify-content-end mb-4 mb-sm-2">
                    <lottie-player class="lottie w-100 h-auto" src="https://assets10.lottiefiles.com/packages/lf20_u6hjxpvu.json" background="none"  speed="1"  loop autoplay></lottie-player> 
                </div>
                <div class="cont_trabajo_descripcion">
                    <div class="cont_textos">
                        <h2 class="subtitulo mx-sm-3">Escaneo</h2>
                        <p class="textos text-justify mx-sm-3">Realizamos captura de datos volumétricos en sitio mediante escaneo por <span>nube de puntos</span> y su procesamiento como realidad virtual tridimensional.</p>
                    </div>
                </div>
            </div>
            <div class="cont_trabajo my-5 p-3 d-sm-flex flex-lg-column">
                <div class="cont_trabajo_lottie px-sm-2 mx-auto d-flex align-items-center justify-content-center justify-content-end mb-4 mb-sm-2">
                    <lottie-player class="lottie w-100 h-auto" src="https://assets9.lottiefiles.com/packages/lf20_deh9njus.json" background="none"  speed="1"  loop autoplay></lottie-player> 
                </div>
                <div class="cont_trabajo_descripcion">
                    <div class="cont_textos">
                        <h2 class="subtitulo mx-sm-3">Modelado 3D</h2>
                        <p class="textos text-justify mx-sm-3">Asignamos <span>equipos dedicados</span> en exclusiva a cada proyecto, liderados por un ingeniero senior con jóvenes ingenieros y desarrolladores talentosos.</p>
                    </div>
                </div>
            </div>
            <div class="cont_trabajo my-5 p-3 d-sm-flex flex-lg-column">
                <div class="cont_trabajo_lottie px-sm-2 mx-auto d-flex align-items-center justify-content-center justify-content-end mb-4 mb-sm-2">
                    <lottie-player class="lottie w-100 h-auto" src="https://assets10.lottiefiles.com/packages/lf20_kupjxeh1.json" background="none"  speed="1"  loop autoplay></lottie-player> 
                </div>
                <div class="cont_trabajo_descripcion">
                    <div class="cont_textos">
                        <h2 class="subtitulo mx-sm-3">Trazado de planos</h2>
                        <p class="textos text-justify mx-sm-3">Desarrollamos planos y documentación <span>precisa y confiable</span> para su fabricación e instalación.</p>
                    </div>
                </div>
            </div>
            <div class="cont_trabajo my-5 p-3 d-sm-flex flex-lg-column">
                <div class="cont_trabajo_lottie px-sm-2 mx-auto d-flex align-items-center justify-content-center justify-content-end mb-4 mb-sm-2">
                    <lottie-player class="lottie w-100 h-auto" src="https://assets2.lottiefiles.com/packages/lf20_7fxioraj.json" background="none"  speed="1"  loop autoplay></lottie-player> 
                </div>
                <div class="cont_trabajo_descripcion">
                    <div class="cont_textos">
                        <h2 class="subtitulo mx-sm-3">Integración de entregables</h2>
                        <p class="textos text-justify mx-sm-3">El resultado son proyectos de ingeniería con un cálculo muy preciso, visualizados <span>tridimensionalmente</span> incluso en escala 1:1.</p>
                        <p class="textos text-justify mx-sm-3">Se entregan con planos de detalle de fabricación e instalación en 3D identificados de manera única con <span>NFT</span>.</p>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<style>
    #section_trabajo .cont_trabajo{
}

.section_cont_trabajo_lotties .cont_trabajo_lottie{
    width: 60%;
}

</style>