<template>
    <section id="socios" class="d-flex ">   
        <div class="section_cont_socios d-flex flex-column reveal scale align-items-center justify-content-center align-items-sm-start py-4 my-4 py-xxl-5">
            <div class="cont_textos px-5 py-3 py-sm-4 py-lg-3 text-center mx-auto">
                <h1 class="subtitulo">Trabajamos con los <span>mejores</span></h1>
            </div>
            <div class="cont_logos d-flex flex-wrap justify-content-center align-items-end w-100 mb-2">
                <div class="logo_socio mx-3 my-3 mx-sm-4 mx-md-5">
                    <a target="_blank" href="https://www.donaldson.com/es-mx/"><img class="w-100" src="../assets/images/logos/donaldson.svg" alt=""></a>
                </div>
                <div class="logo_socio mx-3 my-3 mx-sm-4 mx-md-5">
                    <a target="_blank" href="https://www.rostubos.com/es"><img class="w-100" src="../assets/images/logos/logo_ROS.svg" alt=""></a>
                </div>
            </div>
        </div>                 
    </section>
</template>

<style>
    
.section_cont_socios{
    background: var(--negro);
    width: 100%;    
}

.active.scale{
    -webkit-animation: scale-in-hor-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-hor-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-hor-center {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-hor-center {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      opacity: 1;
    }
  }
  
.section_cont_socios .subtitulo{
    color: var(--blanco);
}


.section_cont_socios .cont_logos .logo_socio{
    width: 40%;
}
</style>