<template>
    <section id="section_proyectos" class="d-flex w-100">   
        <div class="section_cont_proyectos d-flex flex-column py-4 my-4 py-xxl-5">
            <div class="subsection_one d-flex flex-column">
                <div class="cont_textos align-self-end px-5 mt-4 py-sm-4 py-lg-3">
                    <h1 class="titulo text-end">Nuestros <span>trabajos</span> hablan por nosotros</h1>
                </div>
            </div>    

            <div class="subsection_two">
                <div class="cont_proyectos w-100">

                    <div class="cont_proyecto my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center ">
                        <div class="intro_proyecto d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo ">Proyecto</h2>                          
                            </div>
    
                            <div class="cont_img_proyecto my-3 ">
                                <img class="w-100" src="../assets/images/logos/logo_corteva.png" alt="">
                            </div>

                            <div class="cont_textos">
                                <h3 class="subtitulo my-2 "><span>Paine, Chile | 2021</span></h3>
                                <p class="textos">·<span>Tiempo de ejecución:</span> 6 semanas (descontando tiempos de aprobación del cliente).</p>
                                <p class="textos">·<span>CFM´s:</span> 90,000</p>
                                <p class="textos">·<span>Tomas:</span> 118</p>
                                <p class="textos">·<span>Colectores:</span> 7 sistemas</p>
                                <p class="textos">·<span>Retos:</span> Espacio limitado disponible para equipos, en zona sísmica, gran cantidad de tomas, complejidad en balanceo y trayectorias.</p>
                            </div>
                        </div>                       

                        <div class="video_proyecto mx-auto">
                            <video   playsinline autoplay loop muted src="../assets/videos/corteva_project_01.mp4"></video>
                        </div>     
                    </div>

                    <div class="cont_proyecto my-4 my-lg-5 d-xl-flex flex-xl-wrap flex-xl-row-reverse align-items-xl-center">
                        <div class="intro_proyecto d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos">
                                <h2 class="subtitulo text-end">Proyecto</h2>                          
                            </div>
    
                            <div class="cont_img_proyecto align-self-end my-3 ">
                                <img class="w-100" src="../assets/images/logos/logo_hershey.png" alt="">
                            </div>

                            <div class="cont_textos">
                                <h3 class="subtitulo my-3 text-end"><span>Jalisco, México | 2021</span></h3>
                                <p class="textos">·<span>Tiempo de ejecución:</span> 3 semanas  (Montaje en ejecución).</p>
                                <p class="textos">·<span>CFM´s:</span> 9,800</p>
                                <p class="textos">·<span>Tomas:</span> 12</p>
                                <p class="textos">·<span>Colectores:</span> 1 sistema.</p>
                                <p class="textos">·<span>Retos:</span> Polvo explosivo y espacios reducidos.</p>
                            </div>
                        </div>                       

                        <div class="video_proyecto mx-auto">
                            <video  playsinline autoplay loop muted src="../assets/videos/hershey_project_01.mp4"></video>
                        </div>     
                    </div>

                     <div class="cont_proyecto my-4 my-lg-5 d-xl-flex flex-xl-wrap align-items-xl-center">
                        <div class="intro_proyecto d-flex flex-column p-4 px-sm-5 mx-auto">
                            <div class="cont_textos ">
                                <h2 class="subtitulo ">Proyecto</h2>                          
                            </div>
    
                            <div class="cont_img_proyecto my-3 ">
                                <img class="w-100" src="../assets/images/logos/logo_bmw.png" alt="">
                            </div>

                            <div class="cont_textos">
                                <h3 class="subtitulo my-3 "><span>San Luis Potosí | 2021</span></h3>
                                <p class="textos">·<span>Tiempo de ejecución:</span> 6 semanas (descontando tiempos de aprobación del cliente).</p>
                                <p class="textos">·<span>Tiempo de ejecución:</span> 3 semanas.</p>
                                <p class="textos">·<span>CFM’s:</span> 18,000</p>
                                <p class="textos">·<span>Tomas:</span> 3</p>
                                <p class="textos">·<span>Colectores:</span> 2 sistemas.</p>
                                <p class="textos">·<span>Retos:</span> Gestión de espacios reducidos.</p>
                            </div>
                        </div>                       

                        <div class="video_proyecto mx-auto">
                            <video   playsinline autoplay loop muted src="../assets/videos/bmw_project_01.mp4"></video>
                        </div>     
                    </div>
                </div>
            </div>
               
            <div class="subsection_three">
                <div class="cont_textos  px-5 py-3 py-sm-4 py-lg-3">
                    <h3 class="subtitulo text-center"><span>Proyectos</span> realizados para</h3>
                </div>
                <div class="cont_img_proyecto mx-auto">
                    <img class="w-100" src="../assets/images/logos/donaldson.svg" alt="">
                </div>
            </div>   
        </div> 
    </section>
</template>

<style>
#section_proyectos .cont_proyectos .cont_proyecto{
    width: 100%;
}

#section_proyectos  .cont_img_proyecto{
    width: 70%;
}

#section_proyectos  .cont_proyecto:nth-child(3) .cont_img_proyecto{
    width: 50%;
}

#section_proyectos .cont_proyectos .cont_proyecto .video_proyecto{
    overflow:hidden;
    padding: 1rem;
}

#section_proyectos .cont_proyectos .cont_proyecto .video_proyecto video{    
    width: 103%;
}


</style>