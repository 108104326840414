<template>
    <section id="section_header" class="w-100"> 
            <div class="site-mobile-menu">
                <div class="site-mobile-menu-header">
                    <div class="site-mobile-menu-close  px-3">
                        <span class="icon-close2 js-menu-toggle"></span>
                    </div>
                </div>
                <div class="site-mobile-menu-body"></div>
            </div>
        
        <header class="site-navbar position-absolute d-flex align-items-center justify-content-between mb-0 w-100 d-xl-flex justify-content-xl-between align-items-xl-center" >
            <div class=" d-flex w-100 justify-content-between align-items-center px-3">                
                <div class="site-logo mx-xxl-5">
                    <a href="/" class="mb-0 "><img src="../assets/images/logos/dside_logo.svg" alt=""></a>
                </div>

                <div class="mx-auto d-none d-xl-flex mx-xl-5">
                    <nav class="site-navigation position-relative d-none d-xl-flex text-right" role="navigation">
                        <ul class="site-menu js-clone-nav mr-auto d-none d-xl-flex justify-content-xl-center">
                            <li class="m-3 m-sm-2 mx-xl-5 enlaces_menu">
                                <router-link to="/trabajo"><a><span>Cómo lo hacemos</span></a></router-link></li>              
                            <li class="m-3 m-sm-2 mx-xl-5 enlaces_menu">
                                <router-link to="/proyectos"><a><span>Proyectos</span></a></router-link>
                            </li>
                            <li class="m-3 m-sm-2 mx-xl-5 enlaces_menu">
                                <router-link to="/equipo"><a><span>Nosotros</span></a></router-link>
                            </li>      
                            <li class="m-3 m-sm-2 mx-xl-5 enlaces_menu">
                                <router-link to="/historia"><a><span>Historia</span></a></router-link>
                            </li>  
                            <!-- <li class="m-3 m-sm-2 mx-xl-5 enlaces_menu"><a href="#section_foot"><span>Contacto</span></a></li> -->
                       </ul>
                    </nav>
                </div>
            
                <div class="cont_redes_menu position-relative text-center d-none d-xl-flex mx-xl-5" role="navigation">
                    <div class="site-menu js-clone-nav d-flex justify-content-center justify-content-sm-start">            
                       <div class="m-2 m-xl-3  enlaces_menu text-center"><a class="h-100" href="mailto:creal@dside.tech"><i class="far fa-envelope"></i></a></div>
                        <div class="m-2 m-xl-3  enlaces_menu text-center"><a class="h-100" target="_blank" href="https://www.linkedin.com/in/dark-side-engineering-a03095156/"><i class="fab fa-linkedin"></i></a></div>
                    </div>
                </div>
                <div class=" d-xl-none ml-md-0  " style="position: relative; top: 3px;">
                    <a href="#" class="site-menu-toggle js-menu-toggle"><span class="icon-menu h3"></span></a>
                </div>
            </div>   
        </header> 
    </section>
</template>

<style>


#section_header,
.site-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background: var(--blanco);
    z-index: 1999;
    height: 4rem;
    /* -webkit-animation: aparecer 5s linear;
            animation: aparecer 5s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; */
}
/* 
@-webkit-keyframes aparecer{
    0% {
        opacity: 0;
    }
    50%{opacity: 0;}
    100% {
        opacity: 1;
    }
}

@keyframes aparecer{
    0% {
        opacity: 0;
    }
    50%{opacity: 0;}
    100% {
        opacity: 1;
    }
} */

.site-navbar .transparent {
    background: transparent;
}

.site-navbar .site-logo {
      position: relative;
      left: 0;
      width: 5rem;
}

.site-navbar .site-logo img{
    height: 100%;
}

.site-navbar .icon-menu{
    font-size: 3rem;
}

.site-mobile-menu {
    width: 70%;
    position: fixed;
    right: 0;
    z-index: 2000;
    background: var(--blanco);
    height: calc(100vh);
    -webkit-transform: translateX(110%);
    -ms-transform: translateX(110%);
    transform: translateX(110%);
    -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
    box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out; 
}

.offcanvas-menu .site-mobile-menu {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
}

.site-mobile-menu .site-mobile-menu-header {
    width: 100%;
    float: left;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
    float: right;
    margin-top: 8px;
}


.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
    font-size: 3rem;
    display: inline-block;
    cursor: pointer;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

    .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
        max-width: 70px;
    }

    .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
        text-decoration: none;
        color: var(--rojo);
    }

    .site-mobile-menu .site-mobile-menu-body {
        overflow: hidden;
        position: relative;
        padding: 0 20px 20px 20px;
        height: calc(100vh - 52px);
        padding-bottom: 150px;
    }

    .site-mobile-menu .site-nav-wrap {
      padding: 0;
      margin: 0;
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
    }


    .site-mobile-menu .site-nav-wrap a:hover {
        color: var(--rojo)
    }

    .site-mobile-menu .site-nav-wrap .enlaces_menu {
        position: relative;
        display: block;   
    }

    .site-mobile-menu .site-nav-wrap:nth-child(2) {
        position: relative;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }

    .site-mobile-menu .site-nav-wrap:nth-child(2) .enlaces_menu {
        width: 25%;
    }

    .site-mobile-menu .site-nav-wrap:nth-child(2) .enlaces_menu a{
        width: 100%;
        font-size: 2.5rem;
    }

    .site-mobile-menu .site-nav-wrap:nth-child(2) .enlaces_menu a i{
        width: 100%;
    }
 

 
</style>