<template>
    <section id="section_intro" class="d-flex flex-column flex-wrap justify-content-center align-items-center ">   
        <div class="section_cont_intro reveal fade-left d-flex align-items-center align-self-sm-start my-4">
            <div class="cont_textos px-5 px-sm-4 py-3 py-sm-4 p-lg-5 p-xl-5 ">
                <h2 class="subtitulo ">Creamos <span>soluciones</span> en sistemas de extracción y filtración de contaminantes industriales. </h2>
            </div>
        </div> 
        
        <div class="section_cont_intro_video reveal fade-right  align-self-sm-end my-lg-4">
            <video src="../assets/videos/soluciones_02_invert.mp4" playsinline autoplay muted loop ></video>
        </div>
    </section>
</template>

<style>
    #section_intro{
    position: relative;
}

.reveal {
    position: relative;
    opacity: 0;    
  }

 .reveal.active {
    opacity: 1;
  } 


.section_cont_intro{
    -webkit-clip-path: polygon(0 0, 90.4% 0, 68% 100%, 0% 100%);
            clip-path: polygon(0 0, 90.4% 0, 68% 100%, 0% 100%);
    background: var(--negro);
    height: auto;   
}

.active.fade-left{
    -webkit-animation: fade-left 1s ease-in;
            animation: fade-left 1s ease-in;
}


@-webkit-keyframes fade-left {
    0% {
      -webkit-transform: translateX(-100px);
              transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }


@keyframes fade-left {
    0% {
      -webkit-transform: translateX(-100px);
              transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }


.section_cont_intro .cont_textos{
    width: 90%;
}

.section_cont_intro .cont_textos .subtitulo{
    color: var(--blanco);
    font-size: 20px;
}


.section_cont_intro_video{
   
    -webkit-clip-path: polygon(45.6% 0%, 100% 0, 100% 100%, 9.6% 100%);
            clip-path: polygon(45.6% 0%, 100% 0, 100% 100%, 9.6% 100%);  
    /* background: var(--negro); */
    height: auto;      
}

.active.fade-right{
    -webkit-animation: fade-right 1s ease-in;
            animation: fade-right 1s ease-in;
}


@-webkit-keyframes fade-right {
    0% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }


@keyframes fade-right {
    0% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }

  .section_cont_intro_video{

    overflow: hidden;
  }

.section_cont_intro_video video{
    height: 100%;
    width: 100%;
    -o-object-fit:fill;
       object-fit:fill;
}

</style>